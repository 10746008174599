<template>
  <v-dialog v-model="dialog" scrollable width="800" v-if="currentUser">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="mt-3"
        color="secondary"
        tile
        :disabled="currentUser.store.transbankCodeApplied"
        v-bind="attrs"
        v-on="on"
      >
        {{
          currentUser.store.transbankCodeApplied
            ? "Código ya gestionado"
            : "Gestionar código de comercio"
        }}
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        Formulario de solicitud
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-subtitle class="text-subtitle-1 mt-3">
        Para comenzar con los trámites para dar de alta tu código de comercio en
        Transbank vamos a necesitar solicitarte una serie de datos personales.
        <span class="font-weight-medium"
          >Estos datos no se almacenan y son dirigidos directamente a un
          representante comercial de Transbank</span
        >.
        <br />
        Una vez enviado el formulario, un asesor de Transbank se pondrá en
        contacto con usted para finalizar el proceso. Esto puede demorar entre
        24hs a 48hs como máximo.
      </v-card-subtitle>

      <v-divider></v-divider>

      <v-card-text class="text-subtitle-1 mt-3">
        <v-form v-model="valid" lazy-validation ref="form">
          <v-btn-toggle
            v-model="accountType"
            class="fill-width mb-3 d-flex flex-column flex-md-row"
            color="primary"
            tile
            :rules="[(v) => !!v || 'Campo obligatorio']"
          >
            <v-btn class="py-4" value="single"> Soy persona natural </v-btn>
            <v-btn
              class="py-4"
              :style="
                $vuetify.breakpoint.mobile
                  ? 'border-left-width: 1px !important; margin-top: -2px !important'
                  : ''
              "
              value="company"
            >
              Soy empresa
            </v-btn>
          </v-btn-toggle>

          <template v-if="accountType">
            <v-text-field
              filled
              label="Nombre completo"
              v-model="attributes.fullname"
              :rules="[(v) => !!v || 'Campo obligatorio']"
            ></v-text-field>

            <v-text-field
              filled
              :label="$t('transbank.rut')"
              v-model="attributes.rut"
              :rules="[(v) => !!v || 'Campo obligatorio']"
            ></v-text-field>

            <template v-if="accountType == 'company'">
              <v-file-input
                filled
                accept="image/*"
                label="Extracto de la sociedad donde indique al representante legal"
                v-model="file"
              ></v-file-input>

              <v-text-field
                filled
                v-for="field in ['rutRep', 'serialNumber', 'fantasyName']"
                :label="$t('transbank.' + field)"
                v-model="attributes[field]"
                :rules="[(v) => !!v || 'Campo obligatorio']"
              ></v-text-field>
            </template>

            <v-text-field
              filled
              id="map"
              label="Dirección"
              v-model="attributes.address"
              persistent-hint
              no-filter
              :rules="[(v) => !!v || 'Campo obligatorio']"
            ></v-text-field>

            <v-text-field
              filled
              v-for="field in [
                'phone',
                'email',
                'bank',
                'bankAccountType',
                'bankAccountNumber',
              ]"
              :label="$t('transbank.' + field)"
              v-model="attributes[field]"
              :rules="[(v) => !!v || 'Campo obligatorio']"
            ></v-text-field>
          </template>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="send"
          :disabled="!accountType || loading"
          :loading="loading"
        >
          Enviar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { TransbankSignup } from "@/graphql/mutations/payment_method";
import { mapGetters } from "vuex";

export default {
  data: () => ({
    dialog: false,
    loading: false,
    valid: true,
    attributes: {
      fullname: null,
      rut: null,
      rutRep: null,
      serialNumber: null,
      fantasyName: null,
      phone: null,
      email: null,
      address: null,
      bank: null,
      bankAccountNumber: null,
      bankAccountType: null,
    },
    file: null,
    accountType: null,
  }),

  computed: {
    ...mapGetters(["currentUser"]),
  },

  watch: {
    accountType(val) {
      if (val == "single") {
        this.attributes.rutRep = null;
        this.attributes.serialNumber = null;
        this.attributes.fantasyName = null;
        this.file = null;
      }
    },
  },

  methods: {
    send() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        this.$apollo
          .mutate({
            mutation: TransbankSignup,
            variables: {
              input: {
                attributes: this.attributes,
                file: this.file,
              },
            },
          })
          .then((res) => {
            this.dialog = false;
            this.loading = false;
            this.$router.go();
          })
          .catch((err) => {
            this.loading = false;
          });
      }
    },
  },
};
</script>
